.page-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;
}

.header {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    height: 10vh;
    position: absolute;
    top: 6vh;
}

.title {
    font-family: 'Montserrat', sans-serif; 
    font-weight: 700;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.2);
}

h1 {
    font-size: clamp(3rem, 9vw, 8rem);
    line-height: clamp(3.5rem, 4vw, 7rem);
}

.footer {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    height: 10vh;
    position: absolute;
    bottom: 3vh;
}

.footer .item {
    font-size: min(5rem, 5vw);
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: white;
    transition: 0.3s;
}

.footer .item:hover {
    transform: scale(1.1);
}

.info-text {
    font-size: clamp(1rem, 3vw, 2rem);
    margin: 0 10vw;
}